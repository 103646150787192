export const WHITE = '#ffffff';
export const BLACK = '#000000';
export const RED = '#d50753';
export const GREEN = '#90d914';
export const BLUE = '#3e80c3';
export const LIME = '#5ba673';
export const LIGHTER_GRAY = '#f2f2f2';
export const LIGHT_GRAY = '#e0e0e0';
export const DARK_GRAY = '#898989';
export const LIGHT_GREEN = '#e2ffc9';
export const LIGHT_RED = '#ffefef';
export const LIGHT_LIME = '#7bc78b';
export const LIGHT_YELLOW = '#ffeea4';
